.navbar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: transparent;
  z-index: 9;
  min-height: 80px;
}

.navbar .icon-bar {
  color: #fff;
}

.navbar .navbar-nav .nav-link {
  font-size: 13px;
  font-weight: 600;
  color: #eee;
  letter-spacing: 0.5px;
  margin: 15px 5px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.navbar .navbar-nav .nav-link:hover {
  color: #ad0606;
}

.navbar .navbar-nav .active {
  color: #940316 !important;
}

#vxlogo {
  height: 110px;
  width: 175px;
  background-image: url("/images/vxlabs-logo.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.logo1 {
  height: 110px;
  width: 175px;
  background-image: url("/images/vxlabs-logo.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.nav-scroll {
  background: #fff;
  border-bottom: 1px solid rgba(12, 12, 12, 0.04);
  padding: 0;
  position: fixed;
  top: -100px;
  left: 0;
  width: 100%;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
}

.nav-scroll .logo1 {
  height: 110px;
  width: 175px;
  background-image: url("/images/logo-dark-vx.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.nav-scroll .icon-bar {
  color: #222;
}

.nav-scroll .navbar-nav .nav-link {
  color: #989898;
}

.nav-scroll .navbar-nav .logo {
  padding: 15px 0;
  color: #111;
}

.logo {
  padding: 15px 0;
  width: 100px;
}
