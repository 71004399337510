.blog .item {
  -webkit-box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
  box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
  border-radius: 5px;
}

.blog .item .post-img {
  position: relative;
  overflow: hidden;
}

.blog .item .post-img:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.blog .item .post-img img {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.blog .item .post-img .date {
  display: inline-block;
  position: absolute;
  top: 15px;
  left: 15px;
  background: #111;
  color: #fff;
  padding: 10px 15px;
  text-align: center;
}

.blog .item .post-img .date span {
  display: block;
  font-weight: 700;
  font-size: 12px;
}

.blog .item .content {
  padding: 40px 5px;
  background: #fff;
}

.blog .item .content .tag {
  color: #940316;
  font-weight: 600;
  margin-bottom: 10px;
}

.blog .item .content h5 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 15px;
}

.blog-nav {
  position: static !important;
  background: #fff !important;
  margin: 0;
}

.blog-nav .logo {
  color: #1c1c1c !important;
}

.blog-nav .navbar-nav li a {
  color: #1c1c1c !important;
  font-weight: 500;
}

.min-header {
  height: 50vh;
  background: #1c1c1c;
  position: relative;
}

.min-header .v-middle {
  z-index: 4;
}

.min-header h5 {
  font-weight: 700;
  letter-spacing: 1px;
  color: #ccc;
  margin-bottom: 15px;
}

.min-header .path {
  display: inline-block;
  padding: 5px 10px;
  text-align: center;
  background: rgba(100, 100, 100, 0.2);
}

.min-header span {
  position: relative;
  color: #ddd;
  font-style: italic;
  line-height: 1.7;
}

.min-header span:after {
  content: ">>";
  position: relative;
  margin: 0 10px;
}

.min-header span:last-child:after {
  display: none;
}

.blogs .posts .post {
  margin-bottom: 30px;
  border-bottom: 1px solid #eee;
}

.blogs .posts .post .content {
  padding: 30px 15px;
  background: #fff;
}

.blogs .posts .post .content .post-title h5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 10px;
}

.blogs .posts .post .content .meta {
  margin-bottom: 20px;
}

.blogs .posts .post .content .meta li {
  display: inline-block;
  font-size: 12px;
  color: #777;
  margin: 5px;
}

.blogs .posts .post .content p {
  font-weight: 300;
}

.blogs .posts .post .content .spical {
  padding: 15px;
  margin: 30px 0;
  border-left: 2px solid #111;
  background: #f7f7f7;
  font-size: 16px;
}

.blogs .posts .post .content .butn {
  display: inline-block;
  margin-top: 30px;
  padding: 8px 30px;
  border: 2px solid #ddd;
  font-weight: 500;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.blogs .posts .post .content .butn:hover {
  background: #940316;
  border-color: #940316;
  color: #fff;
}

.blogs .posts .post .share-post {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px dashed #eee;
  text-align: left;
}

.blogs .posts .post .share-post span {
  font-weight: 700;
}

.blogs .posts .post .share-post ul {
  float: right;
}

.blogs .posts .post .share-post ul li {
  display: inline-block;
  margin: 0 10px;
}

.blogs .posts .title-g h3 {
  font-weight: 800;
  font-size: 25px;
}

.blogs .posts .comments-area .comment-box {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.blogs .posts .comments-area .comment-box:last-child {
  margin-bottom: 0;
}

.blogs .posts .comments-area .comment-box .author-thumb {
  width: 80px;
  margin-top: -2%;
  float: left;
}

.blogs .posts .comments-area .comment-box .comment-info h6 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.blogs .posts .comments-area .comment-box .comment-info .reply {
  margin-top: 10px;
  font-weight: 600;
}

.blogs .posts .comments-area .comment-box .comment-info .reply i {
  padding-right: 5px;
  font-size: 12px;
}

.blogs .posts .comment-form .form input[type="text"],
.blogs .posts .comment-form .form input[type="email"],
.blogs .posts .comment-form .form textarea {
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 10px;
  background: #f4f4f4;
}

.blogs .posts .comment-form .form textarea {
  height: 160px;
  max-height: 160px;
  max-width: 100%;
}

.blogs .posts .comment-form .form button[type="submit"] {
  background: #940316;
  border: 1px solid #940316;
  color: #fff;
  padding: 10px 30px;
  margin-top: 15px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
}

.blogs .posts .comment-form .form button[type="submit"]:after {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 100%;
  z-index: 1;
  background: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.blogs .posts .comment-form .form button[type="submit"] span {
  position: relative;
  z-index: 2;
}

.blogs .posts .comment-form .form button[type="submit"]:hover:after {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.blogs .posts .comment-form .form button[type="submit"]:hover span {
  color: #940316;
}

.blogs .posts .pagination {
  display: block;
  text-align: center;
}

.blogs .posts .pagination li {
  display: inline-block;
  padding: 5px 10px;
  margin: 2px;
  border: 1px solid #ddd;
}

.blogs .side-bar .widget {
  margin-bottom: 50px;
}

.blogs .side-bar .widget:last-child {
  margin-bottom: 0;
}

.blogs .side-bar .widget .widget-title {
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;
}

.blogs .side-bar .widget .widget-title h6 {
  position: relative;
  padding-bottom: 15px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.blogs .side-bar .widget .widget-title h6:after {
  content: "";
  width: 30px;
  height: 1px;
  background: #000;
  position: absolute;
  bottom: 0;
  left: 0;
}

.blogs .side-bar .widget li {
  margin-bottom: 10px;
  color: #777;
}

.blogs .side-bar .widget li:last-child {
  margin: 0;
}

.blogs .side-bar .search form input {
  width: calc(100% - 52px);
  height: 50px;
  padding: 0 10px;
  border: 0;
  background: #f7f7f7;
}

.blogs .side-bar .search form button {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #1c1c1c;
  color: #fff;
  border: 0;
  float: right;
}
