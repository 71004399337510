.Clients h6 {
  font-size: 12px;
}
.align-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.marquee {
  width: 88vw;
  display: "flex";
  align-self: "center";
  overflow: hidden;
}
.marquee-content {
  display: flex;
  /* animation: scrolling 10s linear infinite; */
}
@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-180vh);
  }
}
.marquee .marquee-content img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 45px;
  flex-shrink: 0;
  white-space: nowrap;
}

@media only screen and (max-width: 500px) {
  .Clients h6 {
    font-size: 8px;
  }
}
