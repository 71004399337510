.contact .map {
    padding: 0;
    position: relative;
    z-index: 4;
  }
  
  .contact #ieatmaps {
    height: 100%;
  }
  
  .information {
    padding: 80px 0;
    color: #fff;
    -webkit-transition: all .5s;
    transition: all .5s;
  }
  
  .information .item {
    padding: 30px;
    background: rgba(70, 70, 70, 0.3);
    border-radius: 5px;
    cursor: pointer;
  }
  
  .information .info .item:last-child {
    margin-bottom: 0;
  }
  
  .information .info .item .icon {
    font-size: 45px;
    float: left;
  }
  
  .information .info .item .cont {
    margin-left: 60px;
  }
  
  .information .info .item .cont h6 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .information .info .item .cont p {
    color: #999;
  }
  
  .contact .map-show {
    left: -100%;
  }
  
  .contact .map-show .icon-toggle {
    z-index: 2;
    -webkit-transition-delay: .3s;
    transition-delay: .3s;
  }
  
  .contact .contact-form {
    padding: 0px 50px;
  }
  
  .contact .contact-form input[type='text'], .contact .contact-form input[type='email'], .contact .contact-form textarea {
    width: 100%;
    padding: 10px;
    border: 0;
    background: white;
    border-radius: 5px;
  }
  
  .contact .contact-form textarea {
    height: 160px;
    max-height: 160px;
    max-width: 100%;
  }
  
  .contact .contact-form button[type='submit'] {
    background: #940316;
    border: 3px solid #940316;
    color: #fff;
    padding: 10px 30px;
    margin-top: 15px;
    font-weight: 600;
    cursor: pointer;
    position: relative;
  }
  
  .contact .contact-form button[type='submit']:after {
    content: '';
    width: 0;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 100%;
    z-index: 1;
    background: #fff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  
  .contact .contact-form button[type='submit'] span {
    position: relative;
    z-index: 2;
  }
  
  .contact .contact-form button[type='submit']:hover:after {
    width: 100%;
    left: 0;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
  }
  
  .contact .contact-form button[type='submit']:hover span {
    color: #940316;
  }