.header {
    min-height: 100vh;
    overflow: hidden;
  }
  
  .header .caption .o-hidden {
    display: inline-block;
  }
  
  .header .caption h3 {
    font-weight: 200;
    letter-spacing: 3px;
    -webkit-animation-delay: .2s;
    animation-delay: .2s;
  }
  
  .header .caption h1 {
    margin: 10px 0;
    font-size: 70px;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-animation-delay: .6s;
    animation-delay: .6s;
  }
  
  .header .caption h1 b {
    color: #940316;
  }
  
  .header .caption p {
    font-size: 16px;
    color: #eee;
    word-spacing: 2px;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  
  .header .caption .butn {
    padding: 10px 30px;
    border: 1px solid #eee;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    position: relative;
    margin: 30px 2px 0;
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
  }
  
  .header .caption .butn:after {
    content: '';
    width: 0;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 100%;
    z-index: 1;
    background: #fff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  
  .header .caption .butn:hover {
    border-color: #fff;
  }
  
  .header .caption .butn:hover:after {
    width: 100%;
    left: 0;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
  }
  
  .header .caption .butn:hover span {
    color: #1c1c1c;
  }
  
  .header .caption .butn span {
    position: relative;
    z-index: 2;
  }
  
  .header .caption .butn-bg {
    background: #940316;
    border-color: #940316;
  }
  
  .header .arrow {
    position: absolute;
    bottom: 5vh;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 13px;
    z-index: 8;
  }
  
  .header .arrow i {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  
  .header .arrow i:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    z-index: -1;
    -webkit-transition: all .2s;
    transition: all .2s;
  }
  
  .header .arrow i:hover:after {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
    -webkit-transition: all .5s;
    transition: all .5s;
  }
  
  .slider .arrow, .slider-fade .arrow {
    display: none !important;
  }
  
  .slider .owl-item, .slider-fade .owl-item {
    height: 100vh;
    position: relative;
  }
  
  .slider .item, .slider-fade .item {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
  }
  
  .slider .item .caption, .slider-fade .item .caption {
    z-index: 9;
  }
  
  .slider .owl-theme .owl-dots, .slider-fade .owl-theme .owl-dots {
    position: absolute;
    bottom: 5vh;
    width: 100%;
  }
  
  .creative {
    min-height: 100vh;
  }
  
  .creative .svg {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    z-index: 2;
  }
  
  .creative .svg svg {
    fill: #fff;
    width: 101%;
  }
  
  .bg-vid {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
  }
  
  #particles-js {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }