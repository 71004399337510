.carousel-control-prev {
  width: 25px;
  height: 30px;
  display: inline-block;
  position: absolute;
  top: 45%;
  left: 0;
}
.carousel-control-next {
  display: inline-block;
  width: 25px;
  height: 30px;
  position: absolute;
  top: 45%;
  right: 1%;
}
