.portfolio {
    overflow: hidden;
  }
  
  .portfolio .filtering span {
    margin-right: 30px;
    color: #333;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    border-bottom: 1px solid transparent;
  }
  
  .portfolio .filtering span:last-child {
    margin: 0;
  }
  
  .portfolio .filtering .active {
    border-color: #940316;
    color: #940316;
  }
  
  .portfolio .items {
    margin-top: 30px;
  }
  
  .portfolio .item-img {
    position: relative;
    overflow: hidden;
  }
  
  .portfolio .item-img:hover .item-img-overlay {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  
  .portfolio .item-img:hover h6 {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  
  .portfolio .item-img h6 {
    font-weight: 600;
    position: relative;
    margin-bottom: 5px;
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
    -webkit-transition: all .5s;
    transition: all .5s;
  }
  
  .portfolio .item-img-overlay {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    background: rgba(0, 0, 0, 0.7);
    color: rgb(214, 205, 205);
    opacity: 0;
    -webkit-transition: all .5s;
    transition: all .5s;
    z-index: 2;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  
  .portfolio .item-img-overlay a {
    font-size: 30px;
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
.portfolio .items {
    margin-top: 30px;
  }
