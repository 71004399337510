.hero .extra-text {
  font-weight: 200;
  margin-bottom: 25px;
}

.hero .extra-text span {
  font-weight: 700;
}

.hero .item {
  background: #fff;
  text-align: center;
  padding: 30px 15px;
  -webkit-box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
  box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
  border-radius: 5px;
  margin-bottom: 30px;
}

.hero .item .icon {
  font-size: 50px;
  margin-bottom: 15px;
  color: #940316;
}

.hero .item h6 {
  margin-bottom: 15px;
}

.hero .mission p {
  margin-bottom: 30px;
}

.hero .mission i {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #940316;
  border-radius: 50%;
  color: #fff;
  font-size: 25px;
}

.hero .mission .small-text {
  margin-left: 10px;
  font-size: 13px;
  color: #666;
}

.hero .skills {
  padding-top: 115px;
}

.hero .skills .prog-item {
  margin-bottom: 25px;
}

.hero .skills .prog-item:last-child {
  margin-bottom: 0;
}

.hero .skills .prog-item p {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 8px;
}

.hero .skills .prog-item .skills-progress {
  width: 100%;
  height: 2px;
  background: #eee;
  border-radius: 5px;
  position: relative;
}

.hero .skills .prog-item .skills-progress span {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #940316;
  width: 10%;
  border-radius: 10px;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.hero .skills .prog-item .skills-progress span:after {
  content: attr(data-value);
  position: absolute;
  top: -20px;
  right: 0;
  font-size: 10px;
  color: #777;
}

@media only screen and (max-width: 750px) {
  .hero .skills {
    padding-top: 40px;
  }
}
