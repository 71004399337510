* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none !important;
  list-style: none;
}
.contact .contact-form input[type="number"],
.contact .contact-form textarea {
  width: 100%;
  padding: 10px;
  border: 0;
  background: white;
  border-radius: 5px;
}

body {
  color: #00000a;
  line-height: 1.3;
  font-weight: 400;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden !important;
  width: 100vw;
}

.goog-te-menu-value {
  height: 10px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

p {
  font-size: 14px;
  color: #666;
  line-height: 1.6;
  word-spacing: 1px;
  margin: 0;
}

img {
  width: 100%;
  height: auto;
}

span,
a,
a:hover {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

.section-head {
  margin-bottom: 80px;
}

.section-head h4 {
  position: relative;
  padding: 0 0 0 15px;
  line-height: 1;
  border-left: 1px solid #eee;
  letter-spacing: 2px;
  font-size: 38px;
  font-weight: 700;
}

.section-head h4:before {
  content: "";
  width: 1px;
  height: 35px;
  background: #940316;
  position: absolute;
  left: -1px;
  bottom: 0;
}

.section-head h4 span {
  font-weight: 200;
  padding-bottom: 5px;
}

.bg-gray {
  background: #f7f7f7;
}

.bg-gray .owl-theme .owl-dots .owl-dot span {
  background: #777;
}

.o-hidden {
  overflow: hidden;
}

.position-re {
  position: relative;
}

.full-width {
  width: 100% !important;
}

.bg-img {
  background-size: cover;
  background-repeat: no-repeat;
}

.section-padding {
  padding: 100px 0;
}

.valign {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.v-middle {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.cd-headline {
  font-size: 3rem;
  line-height: 1.2;
}

@media only screen and (min-width: 768px) {
  .cd-headline {
    font-size: 4.4rem;
    font-weight: 300;
  }
}

@media only screen and (min-width: 1170px) {
  .cd-headline {
    font-size: 6rem;
  }
}

.cd-words-wrapper {
  display: inline-block;
  position: relative;
  text-align: left;
}

.cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
}

.cd-words-wrapper b.is-visible {
  position: relative;
}

.no-js .cd-words-wrapper b {
  opacity: 0;
}

.no-js .cd-words-wrapper b.is-visible {
  opacity: 1;
}

/* xclip */

.cd-headline.clip span {
  display: inline-block;
  padding: 0.2em 0;
}

.cd-headline.clip .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top;
}

.cd-headline.clip .cd-words-wrapper::after {
  /* line */
  content: "";
  position: absolute;
  top: 10%;
  right: 0;
  width: 2px;
  height: 70%;
  background-color: #aebcb9;
}

.cd-headline.clip b {
  opacity: 0;
}

.cd-headline b.is-visible {
  opacity: 1;
  font-weight: 900;
}

.owl-theme .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #f7f8fa;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #940316;
}

/* ----------------------------------------------------------------
   [ 17 Responsive ]
-----------------------------------------------------------------*/

@media screen and (max-width: 991px) {
  .mb-md50 {
    margin-bottom: 50px;
  }
  .mb-md30 {
    margin-bottom: 30px;
  }
  .mb-md0 {
    margin-bottom: 0;
  }
  .navbar .navbar-collapse {
    max-height: 340px;
    overflow: auto;
    background: #111;
    text-align: center;
    padding: 10px 0;
  }
  .navbar .nav-link {
    margin: 5px auto !important;
  }
  .nav-scroll .navbar-collapse .nav-link {
    color: #fff !important;
  }
  .nav-scroll .navbar-collapse .active {
    color: #940316 !important;
  }
  .header .caption h2 {
    font-size: 30px;
  }
  .header .caption h1 {
    font-size: 50px;
  }
  .header .caption p {
    font-size: 16px;
  }
  .serv-tabs .content .bord {
    padding-right: 0;
    margin-bottom: 50px;
  }
  .serv-tabs .content .bord:after {
    display: none;
  }
  .serv-tabs .content .spcial {
    padding-left: 0;
  }
  .contact .map {
    height: auto;
  }
  .information .info {
    margin-bottom: 30px;
  }
  .information {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  .mb-sm50 {
    margin-bottom: 50px;
  }
  .mb-sm30 {
    margin-bottom: 30px;
  }
  .nav-scroll {
    padding-left: 15px;
  }
  .header .caption h3 {
    font-size: 20px;
  }
  .header .caption h1 {
    font-size: 30px;
  }
  .serv-tabs .tabs-icon .item div {
    padding: 15px 0;
  }
}

/*# sourceMappingURL=style.css.map */

/* Footer */
section {
  padding: 60px 0;
}

section .section-title {
  text-align: center;
  color: #007b5e;
  margin-bottom: 50px;
  text-transform: uppercase;
}

#footer {
  background: #007b5e !important;
}

#footer h5 {
  padding-left: 10px;
  border-left: 3px solid #eeeeee;
  padding-bottom: 6px;
  margin-bottom: 20px;
  color: #ffffff;
}

#footer a {
  color: #ffffff;
  text-decoration: none !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

#footer ul.social li {
  padding: 3px 0;
}

#footer ul.social li a i {
  margin-right: 5px;
  font-size: 25px;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

#footer ul.social li:hover a i {
  font-size: 30px;
  margin-top: -10px;
}

#footer ul.social li a,
#footer ul.quick-links li a {
  color: #ffffff;
}

#footer ul.social li a:hover {
  color: #eeeeee;
}

#footer ul.quick-links li {
  padding: 3px 0;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

#footer ul.quick-links li:hover {
  padding: 3px 0;
  margin-left: 5px;
  font-weight: 700;
}

#footer ul.quick-links li a i {
  margin-right: 5px;
}

#footer ul.quick-links li:hover a i {
  font-weight: 700;
}

@media (max-width: 767px) {
  #footer h5 {
    padding-left: 0;
    border-left: transparent;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
}

/*.bg {
  background-image: url('../public/images//footer.html');
}
*/
.dropbtn {
  background-color: #061318;
  color: white;
  padding: 7px;
  font-size: 14px;
  border: 1px solid #ffffff;
  cursor: pointer;
  margin-top: 17px;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 58px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #061318;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: grey;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #061318;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #061318;
}

.skiptranslate {
  display: none !important;
}

.goog-te-banner-frame skiptranslate {
  margin-top: 0px !important;
  display: none !important;
}

.goog-te-banner {
  display: none !important;
  margin-top: 0px !important;
}

body {
  top: 0px !important;
}

a:active {
  color: red;
}

.navbar .nav-item.active {
  border-left: #444 3px solid;
}

.carousel-item {
  height: auto;
}

body {
  margin-top: 0px !important;
}

.txtStyle {
  padding-top: 20%;
  font-size: 12px;
}

tr:first-child td:first-child {
  border-top-left-radius: 10px;
}

tr:first-child td:last-child {
  border-top-right-radius: 10px;
}

tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.borderStyle {
  border: 1px solid white;
  padding: 20px;
  width: 20%;
  text-align: center;
}

.hover_effect:hover {
  /* background-color: #F7F7F7;
  border: 1px solid gray;
  box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15); */
}

.hover_effect > a {
  text-decoration: underline;
  color: #acdbf1 !important;
}

.hover_effect11:hover {
  background-color: white;
}

.serv-tabs .tabs-icon .item div:hover {
  background: #fff;
  color: #940316;
}

.languagepicker {
  display: inline-block;
  padding: 0;
  background-color: transparent;
  color: white;
  border-radius: 5px;
  width: 70%;
  border: 1px solid gray;
  height: 30px;
  overflow: hidden;
  transition: all 0.3s ease;
  margin: 0 50px 10px 0;
  vertical-align: top;
  float: left;
}

.languagepicker:hover {
  /* don't forget the 1px border */
  height: 81px;
}

.languagepicker a {
  color: #000;
  text-decoration: none;
}

.languagepicker li {
  display: block;
  padding: 0px 20px;
  line-height: 15px;
  border-top: 1px solid #eee;
}

.languagepicker li img {
  width: 30%;
  float: left;
}

.languagepicker li:hover {
}

.languagepicker a:first-child li {
}

/*.languagepicker li img {
        margin-right: 5px;
        }*/

.roundborders {
  border-radius: 5px;
}

.large:hover {
  /*
        don't forget the 1px border!
        The first language is 40px heigh,
        the others are 41px
        */
  height: 245px;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.goog-te-gadget-simple {
  background-color: transparent !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 1rem !important;
  line-height: 2rem !important;
  display: inline-block;
  cursor: pointer;
  zoom: 1;
}

.goog-te-gadget-icon {
  /*  background-image: url(../www.shareicon.net/data/2016/07/14/611470_translate_512x512.html) !important; */
  background-position: 0px 0px;
  height: 32px !important;
  width: 32px !important;
  margin-right: 8px !important;
}

.goog-te-menu-value {
  margin-top: 54px !important;
  color: #808080 !important;
}

.placeTrans {
  padding: 6px;
}

.goog-te-menu-value span:nth-child(3) {
  border: none !important;
}

.goog-tooltip {
  display: none !important;
}

.goog-tooltip:hover {
  display: none !important;
}

.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  color: #808080;
}

.button-input {
  position: relative;
}

.button-input button {
  position: absolute;
  right: 75px;
  top: 5px;
  background-color: #fff;
  border: 0;
}

.button-input button i {
  font-size: 22px;
  color: #a10000;
}

.foot1 {
  font-size: 18px;
  color: #4a4f51;
  width: 230px;
  margin-top: 15px;
  margin-left: -35px;
}

.foot2 {
  text-align: center;
  padding: 5px;
  width: 300px;
}

.ico121 {
  background-color: #041319;
  color: #fff;
  border-radius: 50px;
  padding: 8px 9px 8px 9px;
  border: 4px solid #536a5f;
  font-size: 14px;
}

.solutions {
  text-align: justify;
  padding: 15px;
}

.tools {
  width: 100%;
}

.tellus {
  /*background-image: url(../images/background1.jpg);/**/
  background-color: #070e20;
  background-repeat: no-repeat;
  text-align: center;
  color: white;
  padding-bottom: 0%;
}

.modal_width {
  flex: 1;
  /* align-items: center; */
  /* align-content: center; */
  width: 800px;
  margin-top: 17%;
  margin-left: -27%;
}

.FooterForDesktop {
  display: none;
}

.AlienWare {
  border-radius: 0px;
  width: 250px;
  margin-left: 0px;
  line-height: 1.2;
}

.FooterForMobile {
  display: block;
}

.Bt001 {
  height: 50px;
  margin-left: -16px;
}

.tellus {
  height: 500px;
}

.TT {
  margin-left: 10%;
}

.TT1 {
  margin-left: 30px;
}

@media only screen and (min-width: 1230px) {
  .TT {
    margin-top: 80%;
    width: 80%;
    margin-left: 10%;
  }
  .FooterForDesktop {
    display: block;
  }
  .AlienWare {
    margin-left: 175px;
  }
  .button-input button {
    right: -260px;
  }
  .foot1 {
    margin-left: 144px;
  }
  .FooterForMobile {
    display: none;
  }
}

@media only screen and (max-width: 414px) {
  .button-input button {
    right: 70px;
  }
  .nav-scroll .icon-bar {
    margin-left: -73px;
  }
  .another {
    margin-left: 80px;
  }
  .tellus {
    height: auto;
    margin-left: 15px;
  }
  .logo {
    padding: 0px;
  }
  .container {
    margin-left: 15px;
  }
  .Bt001 {
    height: 50px;
    margin-left: 0px;
  }
}

.logoclientvx {
  height: 75px;
  width: 110px;
}

.image-form {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 150px;
  width: 150px;
}

.carousel .item {
  color: #999;
  overflow: hidden;
  min-height: 120px;
  font-size: 13px;
}

.media {
  margin-top: 50px;
}

.carousel .image-form img {
  border-radius: 50%;
}

.carousel .testimonial {
  padding: 0 15px 0 60px;
  position: relative;
}

.carousel .testimonial::before {
  content: "\93";
  color: #e2e2e2;
  font-weight: bold;
  font-size: 68px;
  line-height: 54px;
  position: absolute;
  left: 15px;
  top: 0;
}

.carousel .overview b {
  text-transform: uppercase;
  color: #1c47e3;
}

.testing-serv {
  width: 300px;
}

.card {
  height: 100% !important;
}

.Card_Align {
  text-align: center;
}

.Card_Details {
  line-height: 33px;
}

.industry__icon {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  margin: 0 auto;
  border-radius: 50%;
  line-height: 60px;
  font-size: 30px;
  background: #fff;
  box-shadow: 0 6px 8px rgba(49, 53, 76, 0.1);
}

hr.new1 {
  border-top: 2px solid #c0c0c0;
  width: 60px;
  margin-left: 40% !important;
  margin-right: 40% !important;
}

.Card_Content {
  text-align: center;
  padding: 30px 0px 10px;
}

#Center1 {
  width: 100px;
  height: 100px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  float: center;
  margin: 80px;
  margin-left: 29%;
  margin-right: 50%;
  margin-top: 10%;
  font-size: larger;
}

.count {
  line-height: 100px;
  color: #000000;
  margin-left: 30px;
  font-size: 50px;
  font-weight: inherit;
  font-style: italic;
}

@media only screen and (max-width: 768px) {
  .FooterForDesktop {
    display: none !important;
  }
  .dropdown {
    margin-right: 0px;
  }
  .modal_width {
    flex: 1;
    /* align-items: center; */
    /* align-content: center; */
    width: 90%;
    margin-top: 17%;
    margin-left: 5%;
  }
}

@media only screen and (min-width: 768px) {
  .FooterForMobile {
    display: none !important;
  }
}

.Solution_heading {
  font-size: 17px;
  font-weight: 500;
  padding-left: 3px;
  margin-top: -10px;
  text-transform: uppercase;
}

.Trun {
  margin-top: 5px;
  font-size: 12px;
}

@media only screen and (max-width: 510px) {
  .modal_width {
    flex: 1;
    width: 100%;
    margin-top: 17%;
    margin-left: 0px;
  }
  .section-head {
    margin-bottom: 40px;
  }
  .section-padding {
    padding: 60px 0 !important;
  }
  .button-input button {
    position: absolute;
    right: 18%;
    top: 30%;
    background-color: #fff;
    border: 0;
    background-color: transparent;
  }
  #sender-emoji {
    display: block;
    font-size: 20px;
    color: brown;
    z-index: 9999;
  }
}
