.AlienWare {
  border-radius: 0px;
  border: none;
  width: 250px !important;
  margin-left: 0px;
  line-height: 1.2;
}
@media only screen and (min-width: 1230px) {
  #Sender-emoji {
    position: absolute;
    left: -20px !important;
    top: 5px;
  }
}
/* @media (max-width:767px) {
    #Sender-emoji{
      position: absolute;
      left: -900px !important;

    }
  } */
