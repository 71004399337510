footer {
    background: #111;
    padding: 80px 0;
  }
  
  footer .social a {
    color: #777;
    font-size: 18px;
    margin: 15px 10px;
  }
  
  footer p {
    color: #777;
    font-weight: 700;
  }
  .Footer-img {
    height: 75px;
    width: 140px;
    margin-left: -20px;
    margin-top: -12px;
  }
.social-icon{
  color: white;
  font-size: 15px;
}
.social-icon:hover{
  color: #cf1016;
}
.flex-end{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}