.services .item {
    padding: 30px 15px;
    -webkit-box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
    box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
    margin-bottom: 30px;
  }
.serv-tabs {
    background-attachment: fixed;
  }
  
  .serv-tabs .content .tab-item {
    display: none;
    position: relative;
  }
  
  .serv-tabs .content .curent {
    display: block;
  }
  
  .serv-tabs .content .bord {
    padding-right: 30px;
  }
  
  .serv-tabs .content .bord:after {
    content: '';
    width: 1px;
    height: 100%;
    background: #940316;
    position: absolute;
    right: 1px;
    top: 0;
    opacity: .7;
  }
  
  .serv-tabs .content .spcial {
    padding-left: 30px;
  }
  
  .serv-tabs .content .spcial p {
    color: #fff;
    font-size: 16px;
    font-style: italic;
    word-spacing: 1px;
  }
  
  .serv-tabs .tabs-icon .item div {
    text-align: center;
    padding: 30px 0;
    background: rgba(206, 206, 206, 0.55);
    margin-top: 50px;
    border-radius: 5px;
    cursor: pointer;
    height: 146px;
  }
  
  .serv-tabs .tabs-icon .item .icon {
    font-size: 30px;
    color: #940316;
    margin-bottom: 15px;
  }
  
  .serv-tabs .tabs-icon .item h6 {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  
  .serv-tabs .tabs-icon .active div {
    background: #fff;
  }
  
  .serv-tabs .tabs-icon .active h6 {
    color: #111;
  }