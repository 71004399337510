.Exchange .item-img:hover .item-img-overlay {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}
.Exchange .item-img-overlay {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    background: rgba(255, 255, 255, 0.95);
    color: #35424C;
    opacity: 0;
    -webkit-transition: all .5s;
    transition: all .5s;
    z-index: 2;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
}